.userList_wrapper{
    text-align: left;
    padding: 36px 88px 36px 40px;
}

.userList_title{
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 25px;
    font-family: Roboto;
}

.userList_table{
    margin-top: 20px;
}

.table_actions_wrapper{
    display: flex;
    column-gap: 5px;
}

.user_list_top_wrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.collapse_images_content{
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
}

.potential_emp_wrapper{
    margin-top: 7px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
}

.make_emp_button{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    font-family: Roboto;
    border-radius: 2px;
}

.add_user_button{
    font-size: 14px;
    line-height: 22px;
    background: #1890FF;
}

.potential_emp_desc{
    /*display: flex;*/
}

.potential_emp_label{
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    font-family: Roboto;
    color: #ABABAB;
}

.potential_emp_value{
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    font-family: Roboto;
    color: #2c2d2e;
}

.potential_emp_top{
    display: flex;
    column-gap: 24px;
}

.potential_emp_footer{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;
}

.more_images_button{
    background: #E6EEFF !important;
    color: #1890FF;
    border-radius: 4px;
    border: unset;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 16px;
}

.images_count{
    background: white;
    padding: 0 4px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: Roboto;
    border-radius: 4px;
    margin-left: 6px;
}

.potential_emp_subtitle{
    margin-top: 14px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-family: Roboto;
    color: #ABABAB;
}

.img_modal_title{
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    font-family: Roboto;
    color: #000000D9;
}

.img_modal_img_wrapper{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
}

.modal_button{
    width: 198px;
    border-radius: 4px;
    margin-top: 36px;
}

.contacts{
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    /*margin-bottom: 24px;*/
}

.modal_day_wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 8px;
    background: #F7F7F7;
    border-radius: 4px;
    margin-bottom: 8px;
}

.modal_day_wrapper:nth-last-child(1){
    margin-bottom: unset;
}

.modal_day_title{
    font-weight: 600;
    font-size: 14px;
    color: #2C2D2E;
}

.modal_day_timers{
    display: flex;
    column-gap: 8px;
}

.modal_contact{
    background: #F7F7F7;
    border-radius: 4px;
    width: 100%;
}

.modal_contact_title{
    font-size: 14px;
    font-weight: 600;
    color: #2C2D2E;
}

.top_buttons_wrapper{
    display: flex;
    column-gap: 16px;
}

.import_error{
    margin-bottom: 20px;
}

.import_error:nth-last-child(1){
    margin-bottom: unset;
}

.layout_switcher{
    display: flex;
    column-gap: 8px;
}

.layout_option{
    padding: 4px;
    background: #F6F6F6;
    border-radius: 4px;
    color: #2C2D2E;
    display: flex;
    cursor: pointer;
    transition: 0.5s all;
}

.layout_option_active{
    background: #1890FF !important;
    color: white !important;
}

.cards_wrapper{
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    width: 100%;
    margin-top: 25px;
}

.card{
    border-radius: 8px;
    background: white;
    box-shadow: 0px -1px 12px 6px #00000026;
    padding: 10px;
    width: calc(50% - 8px);
    display: flex;
    column-gap: 10px;
}

.card_info_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.card_info_name{
    font-size: 18px;
    line-height: 16px;
    font-weight: 500;
    font-family: Roboto;
}

.card_info_type{
    border-radius: 4px;
    padding: 0 6px;
    background: #D9EDFF;
    color: #1890FF;
    font-size: 14px;
    line-height: 22px;
    font-family: Roboto;
}

.divider{
    width: 100%;
    height: 1px;
    background: #F4F4F4;
    margin: 10px 0;
}

.card_info_block{
    min-width: 160px;
}

.card_info_label{
    color: #A1A1A1;
    font-size: 13px;
    line-height: 22px;
    font-family: Roboto;
}

.card_info_value{
    font-size: 14px;
    line-height: 22px;
    color: #212121;
    font-family: Roboto;
}

.card_info_wrapper{
    display: flex;
    column-gap: 20px;
}

.card_info_button_wrapper{
    display: flex;
    justify-content: flex-end;
    margin-top: 19px;
}

.card_info_button{
    font-size: 14px;
    line-height: 22px;
    padding: 0 7px;
    height: 24px;
}

.user_modal_title{
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    font-family: Roboto;
    color: black;
}

.user_modal_top{
    display: flex;
    column-gap: 30px;
    margin-top: 20px;
}

.user_modal_info_block{
    display: flex;
    background: #F5F6F8BF;
    border-radius: 12px;
    padding: 12px;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 16px;
}

.user_modal_divider{
    width: 100%;
    height: 1px;
    background: #EDEDED;
    margin: 16px 0;
}

.user_modal_buttons_wrapper{
    display: flex;
    column-gap: 8px;
    margin-top: 20px;
}

.user_modal_button{
    padding: 0 7px;
    height: 24px;
    border-radius: 4px;
    box-shadow: unset;
}

.expanded_row{
    display: flex;
    column-gap: 32px;
}

.contacts_title{
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    font-family: Roboto;
    color: #2C2D2E;
}

.contacts_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contacts_wrapper{
    border-radius: 12px ;
    padding: 12px;
    background: #F8F8F8;
}

.contact_delete{
    border: unset !important;
    background: transparent !important;
    box-shadow: unset !important;
}

.contacts_line{
    margin: 24px 0;
    width: 100%;
    height: 1px;
    background: #CCCCCC99;
}
