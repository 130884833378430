.blacklist_controls{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filters_button{
    width: 40px !important;
    height: 40px !important;
    margin-right: 12px;
    border-radius: 6px;
}

.blacklist_controls_filters{
    display: flex;
    align-items: center;
}

.blacklist_filters_text{
    font-size: 16px;
    line-height: 24px;
}

.filters_datepicker{
    width: 256px;
    margin-left: 16px;
    height: 40px;
}

.blacklist_filters_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 33px;
}

.filter_block{
    text-align: left;
}

.filter_title{
    font-size: 14px;
    line-height: 24px;
    color: #000000D9;
}

.checkbox_wrapper{
    margin-top: 6px;
}

.period_select{
    width: 210px;
    /*height: 24px;*/
    margin-top: 4px;
}

.clear_filters{
    margin-top: 16px;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    color: #1890FF;
    display: inline-block;
    float: right;
}

.filters_block{
    position: absolute;
    width: 100%;
    background: white;
    z-index: 2;
    top: 37px;
}

.blacklist_clients_wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.visitor_card{
    border-radius: 4px;
    border: 2px solid #DFDFDF;
    background: white;
    display: flex;
}

.visitor_info_block{
    padding: 12px 12px 12px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.visitor_info_part{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.visitor_name{
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #2C2D2E;
}

.visitor_status_wrapper{
    display: flex;
    column-gap: 4px;
}

.visitor_status{
    padding: 4px;
    border-radius: 4px;
    width: 48px;
    height: 48px;
}

.visits_label{
    color: #2C2D2E;
    font-size: 13px;
    line-height: 22px;
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.visits_count{
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    color: #2C2D2E;
    margin-top: 4px;
}

.visitor_history_button{
    width: 190px !important;
    height: 32px !important;
    border: 1px solid #D9D9D9 !important;
    background: white !important;
    color: #000000D9 !important;
    font-size: 14px;
    line-height: 22px;
}

.visitor_bottom_part{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F7F7F7;
    border-radius: 8px;
    padding: 8px;
}

.visitor_bases_wrapper{
    display: flex;
    column-gap: 4px;
}

.visitor_edit_button{
    background: #1890FF !important;
    color: white !important;
    border: unset !important;
    border-radius: 2px;
    width: 155px !important;
    height: 32px !important;
}

.visitor_local_base{
    background: #EDEDED;
    border-radius: 2px;
    padding: 4px 10px;
    color: black;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    column-gap: 4px;
}

.visitor_premium_base{
    background: linear-gradient(87.63deg, #9747FF -13.62%, #0B59F0 55.7%, #5E00D9 109.07%);
    border-radius: 2px;
    padding: 4px 14.5px;
    color: white;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    column-gap: 4px;
}

.current_visitor{
    padding-top: 87px;
}

.current_visitor_image_wrapper{
    position: relative;
    margin: 0 auto;
    width: 822px;
    height: 822px;
    border-radius: 6px;
}

.current_visitor_status{
    position: absolute;
    top: 7px;
    left: 7px;
}

.current_visitor_close_button{
    position: absolute;
    top: 7px;
    right: 7px;
    width: 100px !important;
    height: 32px !important;
    background: white !important;
    border: unset !important;
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: #2C2D2E !important;
    padding: 4px;
}

.current_visitor_visits{
    margin-top: 16px;
    padding: 24px;
    background: #F2F2F2;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.current_visitor_actions{
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
}

.action_button{
    width: 30% !important;
    height: 56px !important;
    border-radius: 2px;
}

.wrong_person{
    border: 1px dashed #FF4D4F !important;
    color: #FF4D4F !important;
    background: white !important;
}

.pass{
    border: unset !important;
    color: white !important;
    background: #33B41E !important;
}

.reject{
    border: unset !important;
    color: white !important;
    background: #FF4D4F !important;
}

.history_modal_title{
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    color: #2C2D2E;
}

.history_visit_title{
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 500;
    color: #282727;
}

.history_visit_label{
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 400;
    color: #777777;
    margin-top: 12px;
}

.history_visit_date{
    font-size: 14px;
    line-height: 16.8px;
    color: #2C2D2E;
}

.history_comment_title{
    font-size: 14px;
    line-height: 16.8px;
    color: #2C2D2E;
    font-weight: 500;
}

.history_comment_text{
    font-size: 16px;
    line-height: 19.2px;
    color: #2C2D2E;
    margin-top: 8px;
}

.history_comment_info{
    display: flex;
    align-items: center;
    column-gap: 80px;
    margin-top: 12px;
}

.edit_modal_title{
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    color: #2C2D2E;
}

.edit_form_label{
    font-size: 14px;
    line-height: 22px;
    color: #2C2D2E;
}

.edit_form_buttons{
    display: flex;
    column-gap: 8px;
}

.edit_form_save{
    background: #1890FF !important;
    color: white !important;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    width: 198px !important;
    height: 32px !important;
    border: unset !important
}

.edit_form_cancel{
    background: white !important;
    color: #000000D9 !important;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    width: 198px !important;
    height: 32px !important;
    border: 1px solid #D9D9D9 !important
}

.face_canvas{
    position: absolute;
    cursor: pointer;
}
