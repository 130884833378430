body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-spin{
  max-height: unset !important;
}

.main_spinner{
  height: 100vh !important;
  position: fixed !important;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@font-face {
  font-family: MailSansRoman;
  src:url('./fonts/MailSansRoman-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: MailSansRoman;
  src:url('./fonts/MailSansRoman-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: MailSansRoman;
  src:url('./fonts/MailSansRoman-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: MailSansRoman;
  src:url('./fonts/MailSansRoman-DemiBold.ttf');
  font-weight: 600;
}

.menu_wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 108px);
}

.custom_menu_item{
  padding: 0 16px 0 24px;
  margin: 4px;
  width: calc(100% - 8px);
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  span:nth-last-child(1){
    margin-inline-start: 10px;
  }
  position: absolute;
  bottom: 60px;
  overflow: hidden;
}

.sider{
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  inset-inline-start: 0;
  top: 0;
  bottom: 0;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  transition: 0.5s all !important;
}

.ant-layout-sider-trigger{
  transition: 0.5s all !important;
}
