.registration_wrapper{
    position: relative;
    height: calc(100svh - 32px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*START PAGE STYLES*/
.creatti_logo{
    position: absolute;
    top: 34.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.gradient{
    position: absolute;
    width: 205px;
    height: 442px;
    transform: rotate(70deg);
    background: transparent;
    border-radius: 100%;
    left: -33px;
    bottom: 27px;
}

.gradient:before{
    content: '';
    inset: -.625em;
    width: 100%;
    height: 100%;
    background: linear-gradient(70deg, rgba(49,208,84,1) -5%, rgba(15,100,228,1) 100%);
    position: absolute;
    filter: blur(5.5em);
    z-index: -1;
}

.button_wrapper{
    width: 100%;
    position: absolute;
    bottom: 18px;
}

.begin_button{
    font-family: MailSansRoman;
    background: #005FF9;
    width: 100%;
    height: 44px;
    font-size: 16px;
    font-weight: 500;
}

.copyright{
    margin-top: 35px;
    font-size: 12px;
}

/*FORM PAGE STYLES*/

.form_page_wrapper{
    height: calc(100% - 81px);
    overflow-y: scroll;
}

.form_page_wrapper::-webkit-scrollbar{
    display: none;
}

.info_block{
    border-radius: 12px;
    padding: 14px;
    background: #EFF6FF;
    text-align: left;
}

.info_title{
    color: #005FF9;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    font-family: MailSansRoman;
}

.info_point{
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    font-family: MailSansRoman;
    color: #2C2D2E;
}

.info_slider{
    height: 10px;
    background: #3683FF29;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 4px;
}

.info_slider_point{
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: linear-gradient(36deg, rgba(49,208,84,1) 0%, rgba(15,100,228,1) 100%);
}

.info_slider_point_active{
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: linear-gradient(36deg, rgba(49,208,84,1) 0%, rgba(15,100,228,1) 100%);
    z-index: 1;
}

.info_slider_point_active:before{
    content: '';
    width: 10px;
    height: 10px;
    background: white;
    position: absolute;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slider_point_tooltip{
    position: absolute;
    background: #3683FF29;
    border-radius: 4px;
    padding: 4px;
    color: white;
    font-size: 12px;
    top: -30px;
    left: -10px;
    font-family: Inter;
    font-weight: 600;
    line-height: 15px;
}

.slider_track{
    position: absolute;
    top: 0;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: linear-gradient(36deg, rgba(49,208,84,1) 0%, rgba(15,100,228,1) 100%);
}

.form_wrapper{
    margin-top: 36px;
}

.form_input{
    height: 44px;
    width: 100%;
}

.form_text{
    text-align: left;
    color: #87898F;
    font-family: MailSansRoman;
    font-weight: 300;
}

.interests_wrapper{
    margin-top: 36px;
}

.interests_title{
    text-align: left;
    font-weight: 500;
    font-family: Manrope;
    font-size: 18px;
    line-height: 20px;
    color: #2C2D2E;
}

.interests_subtitle{
    margin-top: 8px;
    color: #87898F;
    text-align: left;
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.tags_wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 20px;
}

.interests_tag{
    background: #00000014;
    color: #1B1F3BCC;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Manrope;
    padding: 8px 12px;
    border-radius: 8px;
    transition: 0.3s all;
}

.tag_active{
    background: #005FF9;
    color: white;
}

.next_button_wrapper{
    background: white;
    position: absolute;
    bottom: -16px;
    height: 98px;
    width: 100vw;
    padding: 16px;
}

.next_button{
    color: white;
    height: 44px;
    width: 100%;
    background: #005FF9;
    font-family: MailSansRoman;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.back_button{
    color: #005FF9;
    border: unset;
    box-shadow: unset;
    margin-top: 25px;
    font-size: 16px;
    line-height: 20px;
    font-family: Manrope;
    font-weight: 500;
}

.get_10_button{
    color: #005FF9;
    border: 1px solid #005FF9;
    background: white;
    width: 100%;
    height: 44px;
    font-family: MailSansRoman;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
}

.bigger_wrapper{
    height: 136px;
}

.double_button_hor{
    display: flex;
    column-gap: 8px;
}

.form_checkbox{
    margin-top: 42px;
    font-size: 14px;
    font-family: Manrope;
    font-weight: 400;
    line-height: 20px;
}

/*INFO PAGE STYLES*/
.face_icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*FACE SCAN PAGE STYLES*/

/*SUCCESS PAGE STYLES*/
.success_page_logo{
    margin-top: 98px;
}

.success_box{
    margin-top: 26px;
}

.success_title{
    margin-top: 31px;
    font-family: MailSansRoman;
    font-size: 32px;
    font-weight: 600;
    line-height: 20px;
    color: #2C2D2E;
}

.success_subtitle{
    margin-top: 31px;
    font-family: Manrope;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #2C2D2E;
}

.success_more{
    margin-top: 110px;
    font-family: MailSansRoman;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #87898F;
}

/*FACESCNA PAGE STYLES*/
.facescan_wrapper{
    width: 100vw;
    height: calc(100svh - 136px);
    top: -16px;
    background: black;
    position: absolute;
    opacity: 0.6;
    mask: url('../../images/mask_image.png') center no-repeat, linear-gradient(#000 0 0);
    mask-composite: exclude;
    z-index: 1;
}

.facescan_button_wrapper{
    background: white;
    position: absolute;
    bottom: -16px;
    height: 136px;
    width: 100vw;
    padding: 16px;
}

.facescan_flash{
    background: white;
    position: absolute;
    width: 100vw;
    height: 100svh;
    top: -16px;
    z-index: 0;
    opacity: 0;
}

.facescan_video{
    display: none;
}

.facescan_canvas{
    position: absolute;
    top: -16px;
    left: -16px;
}

.start_flash{
    animation: flash 0.5s;
    animation-iteration-count: 1;
}

@keyframes flash {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
        z-index: 1;
    }
    100% {
        opacity: 0;
    }
}
