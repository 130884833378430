.appvide{
    display: flex;
    align-items: center;
}

.appcanvas{
    position: absolute;
    /*top: 100px;*/
}

.myapp{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
