.portal_users_title{
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    font-family: Roboto;
}

.branch_wrapper{
    border-radius: 8px;
    box-shadow: 0px 0px 6px 2px #0000001A;
    padding: 12px;
}

.branch_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.branch_top_right{
    display: flex;
    column-gap: 8px;
    align-items: center;
}

.branch_title{
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    color: #1890FF;
}

.tag{
    padding: 0 8px;
    border-radius: 1px;
    font-size: 14px;
    line-height: 22px;
}

.green_tag{
    color: #52C41A;
    background: #F6FFED;
    border: 1px solid #B7EB8F
}

.blue_tag{
    background: #F0F5FF;
    border: 1px solid #ADC6FF;
    color: #2F54EB;
}

.branch_edit_button{
    height: 24px !important;
    padding: 0 8px;
}

.branch_users_wrapper{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.branch_desc_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.branch_user_title{
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-size: 14px;
    line-height: 22px;
    color: #2C2D2E;
    font-weight: 600;
}

.branch_user_value{
    color: #2C2D2E;
    font-size: 18px;
    line-height: 22px;
    margin-top: 4px;
}

.branch_user{
    font-size: 18px;
    line-height: 22px;
    color: #2C2D2E;
}

.branch_add_user, .branch_add_item{
    margin-top: 10px;
    padding: 0 7px;
    line-height: 22px;
    font-size: 14px;
    height: 22px;
}

.branches_wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 33px;
}

.portal_users_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.portal_users_header_right{
    display: flex;
    align-items: center;
    column-gap: 16px;
}
