.filtration_wrapper{
    text-align: left;
    margin-bottom: 20px;
}

.filtration_title{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.warning_message{
    text-align: center;
    font-size: 24px;
    font-weight: 700;
}

.client_input{
    width: 300px;
    margin-right: 20px;
}

.action_buttons_wrapper{
    display: flex;
    column-gap: 10px;
}
