.userList_wrapper{
    text-align: left;
}

.userList_title{
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 16px;
    font-family: Roboto;
}

.userList_table{
    margin-top: 20px;
}

.table_actions_wrapper{
    display: flex;
    column-gap: 5px;
}

.user_list_top_wrapper{
    display: flex;
    justify-content: space-between;
}

.collapse_images_content{
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
}

.potential_emp_wrapper{
    margin-top: 7px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
}

.make_emp_button{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    font-family: Roboto;
    border-radius: 2px;
}

.add_user_button{
    font-size: 14px;
    line-height: 22px;
    background: #1890FF;
}

.potential_emp_desc{
    /*display: flex;*/
}

.potential_emp_label{
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    font-family: Roboto;
    color: #ABABAB;
}

.potential_emp_value{
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    font-family: Roboto;
    color: #2c2d2e;
}

.potential_emp_top{
    display: flex;
    column-gap: 24px;
}

.potential_emp_footer{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;
}

.more_images_button{
    background: #E6EEFF !important;
    color: #1890FF;
    border-radius: 4px;
    border: unset;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 16px;
}

.images_count{
    background: white;
    padding: 0 4px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: Roboto;
    border-radius: 4px;
    margin-left: 6px;
}

.potential_emp_subtitle{
    margin-top: 14px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-family: Roboto;
    color: #ABABAB;
}

.img_modal_title{
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    font-family: Roboto;
    color: #000000D9;
}

.img_modal_img_wrapper{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
}

.modal_button{
    width: 198px;
    border-radius: 4px;
    margin-top: 24px;
}

.import_error{
    margin-bottom: 20px;
}

.import_error:nth-last-child(1){
    margin-bottom: unset;
}

.top_buttons_wrapper{
    display: flex;
    column-gap: 8px;
}
