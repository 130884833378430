@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700&display=swap');

.loginBackground {
    background-image: url("../../images/foquslogin.png");
    background-size: cover;
}

.wrapperLoginPageForLeftSide {
    width: 500px;
    margin-top: 100px;
    margin-left: 111px;
}

.mainTitle {
    font-size: 48px;
    color: white;
    font-family: 'Nunito Sans', sans-serif;
    line-height: 48px;
    margin-top: 67px;
    font-weight: 200;
}

.secondTitle {
    font-size: 24px;
    color: white;
    font-family: 'Nunito Sans', sans-serif;
    margin-top: 80px;
    font-weight: 400;
}

.loginText {
    font-size: 18px;
    color: white;
    font-family: 'Nunito Sans', sans-serif;
    margin-top: 80px;
    font-weight: 400;
}