.filtration_wrapper{
    text-align: left;
    margin-bottom: 20px;
}

.filtration_title{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.filtration_filters{
    display: flex;
}
