.userList_wrapper{
    text-align: left;
}

.userList_title{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.userList_table{
    margin-top: 20px;
}

.table_actions_wrapper{
    display: flex;
    column-gap: 5px;
}

.user_list_top_wrapper{
    display: flex;
    justify-content: space-between;
}

.collapse_images_content{
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
}

.potential_emp_wrapper{
    margin-top: 20px;
}

.make_emp_button{
    position: absolute;
    top: 20px;
    right: 20px;
}
