.bad_images_wrapper{
    text-align: left;
}

.bad_images_card_wrapper{
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 20px;
}

.bad_images_title{
    margin-bottom: 20px;
}

.bad_images_datepicker{
    width: 300px;
    margin-right: 20px;
}

.load_more_button{
    margin: 0 auto;
    display: block;
}
