.duplicates_wrapper{
    text-align: left;
}

.duplicates_title{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.duplicates_datepicker{
    width: 300px;
    margin-bottom: 20px;
    margin-right: 20px;
}

.duplicates_spin{
    position: fixed;
}

.duplicates_card_wrapper{
    display: flex;
    margin-bottom: 20px;
}

.duplicates_card_title{
    /*margin-bottom: 10px;*/
}

.duplicates_card_description{
    margin-left: 20px;
}

.duplicates_card_content{
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
}

.collapse_controls{
    display: flex;
    position: absolute;
    align-items: center;
    z-index: 1;
    right: 40px;
    top: 11px;
}

.collapse_size_input{
    width: 25px;
}

.duplicates_title_wrapper{
    display: flex;
    column-gap: 20px;
    align-items: center;
    margin-bottom: 10px;
}

.merge_buttons_wrapper{
    display: flex;
    column-gap: 10px;
    margin-right: 15px;
}

.collapse_merge_buttons{
    border: unset !important;
}

.merge_button{
    margin-top: 10px;
}

.load_more_button{
    margin: 0 auto;
    display: block;
}
