body {
    background-color: #ffffff;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    min-height: 100svh !important;
}

header {
    background-color: #f1f1f1;
    padding: 20px;
    text-align: center;
}

.ant-layout-content {
    margin: 0 !important;
}

h1 {
    margin: 0;
}

main {
    margin: 20px;
    text-align: center;
}

p {
    margin-bottom: 20px;
}

a {
    color: #000000;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

i {
    color: orange;
    font-style: normal;
}

.quicksight-embedding-iframe {
    width: 100%;
    height: 100vh;
    display: none;
}
.ant-layout-content div .quicksight-embedding-iframe {
    display: block;
}

.player-wrapper {
    width: 30%;
    height: auto;
}
